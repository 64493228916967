import { HTMLProps, useCallback, useState } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

interface SearchInputProps extends HTMLProps<HTMLInputElement> {
  noCancelBtn?: boolean
  noBg?: boolean
  clearBtn?: boolean
  heightFull?: boolean
  widthFull?: boolean
  isFocused?: (focused: boolean) => void
  focused?: boolean
}

export default function SearchInput(props: SearchInputProps) {
  const {
    value,
    noCancelBtn,
    noBg = false,
    clearBtn,
    heightFull = false,
    widthFull = false,
    isFocused,
    focused: forceFocused,
    className,
    ...domProps
  } = props
  const [innerFocused, setFocused] = useState<boolean>(false)
  const focused = forceFocused ?? innerFocused

  const clear = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()
      if (props.onChange) {
        props.onChange({
          target: {
            value: '',
          },
        } as any)
      }
    },
    [props]
  )

  const handleFocus = () => {
    setFocused(true)
    isFocused?.(true)
  }

  const handleBlur = () => {
    setFocused(false)
    isFocused?.(false)
  }

  return (
    <div
      className={classNames('SearchInput', {
        'SearchInput--h-full': heightFull,
        'SearchInput--w-full': widthFull,
      })}
    >
      <input
        className={classNames('SearchInput-input', className, {
          'SearchInput-input--with-cancel': !noCancelBtn,
          'SearchInput-input--with-bg': !noBg,
        })}
        placeholder="Search"
        value={value}
        onChange={props.onChange}
        autoComplete="off"
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...domProps}
      />

      <IconSearch />

      {noCancelBtn || (
        <motion.button
          className="SearchInput-cancel-button"
          type="button"
          onMouseDown={handleBlur}
          animate={{
            x: focused ? 0 : 100,
            opacity: focused ? 1 : 0,
          }}
          transition={{
            duration: 0.2,
          }}
        >
          Cancel
        </motion.button>
      )}

      {clearBtn && value && (
        <button
          className="SearchInput-clear-button"
          type="button"
          onMouseDown={clear}
        >
          <IconClear />
        </button>
      )}
    </div>
  )
}

function IconSearch() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.12484 11.0833C3.38643 11.0833 1.1665 8.86341 1.1665 6.125C1.1665 3.38659 3.38643 1.16667 6.12484 1.16667C8.86325 1.16667 11.0832 3.38659 11.0832 6.125C11.0832 7.28401 10.6855 8.35014 10.0192 9.19439L12.6623 11.8375C12.8901 12.0653 12.8901 12.4347 12.6623 12.6625C12.4345 12.8903 12.0652 12.8903 11.8374 12.6625L9.19423 10.0194C8.34997 10.6857 7.28384 11.0833 6.12484 11.0833ZM8.84303 8.76852C8.82963 8.77971 8.81661 8.7916 8.80403 8.80419C8.79144 8.81678 8.77955 8.82979 8.76836 8.84319C8.08537 9.50753 7.15289 9.91667 6.12484 9.91667C4.03076 9.91667 2.33317 8.21908 2.33317 6.125C2.33317 4.03092 4.03076 2.33333 6.12484 2.33333C8.21892 2.33333 9.9165 4.03092 9.9165 6.125C9.9165 7.15305 9.50736 8.08554 8.84303 8.76852Z"
        className="fill-current"
      />
    </svg>
  )
}

function IconClear() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14">
      <path
        d="M7.00001 13.4173C3.45618 13.4173 0.583344 10.5445 0.583344 7.00065C0.583344 3.45682 3.45618 0.583984 7.00001 0.583984C10.5438 0.583984 13.4167 3.45682 13.4167 7.00065C13.4167 10.5445 10.5438 13.4173 7.00001 13.4173ZM6.17505 7.00065L4.83753 8.33817C4.60973 8.56598 4.60973 8.93532 4.83753 9.16313C5.06534 9.39093 5.43468 9.39093 5.66249 9.16313L7.00001 7.82561L8.33753 9.16313C8.56534 9.39093 8.93468 9.39093 9.16249 9.16313C9.39029 8.93532 9.39029 8.56598 9.16249 8.33817L7.82497 7.00065L9.16249 5.66313C9.39029 5.43532 9.39029 5.06598 9.16249 4.83817C8.93468 4.61037 8.56534 4.61037 8.33753 4.83817L7.00001 6.17569L5.66249 4.83817C5.43468 4.61037 5.06534 4.61037 4.83753 4.83817C4.60973 5.06598 4.60973 5.43532 4.83753 5.66313L6.17505 7.00065Z"
        className="fill-current"
      />
    </svg>
  )
}
