import { useIntl } from 'react-intl'
import classNames from 'classnames'

interface DeltaAbsNumberProps {
  value: number
  disabled?: boolean
  alwaysBlue?: boolean
}

export function DeltaAbsNumber(props: DeltaAbsNumberProps) {
  const { value, disabled, alwaysBlue } = props
  const intl = useIntl()
  const negative = value < 0
  const text = intl.formatNumber(value, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: 'always',
  })

  return (
    <span
      className={classNames('DeltaAbsNumber', {
        'DeltaAbsNumber--disabled': disabled,
        'DeltaAbsNumber--negative': negative && !alwaysBlue,
      })}
    >
      {text}
    </span>
  )
}

interface DeltaRelNumberProps {
  value: number
  disabled?: boolean
  alwaysBlue?: boolean
}

export function DeltaRelNumber(props: DeltaRelNumberProps) {
  const { value, disabled, alwaysBlue } = props
  const intl = useIntl()
  const roundedValue = Math.round(value * 10000) / 10000
  const negative = roundedValue < 0

  return (
    <span
      className={classNames('DeltaRelNumber', {
        'DeltaRelNumber--disabled': disabled,
        'DeltaRelNumber--negative': negative && !alwaysBlue,
      })}
    >
      (
      {intl.formatNumber(Math.abs(value), {
        style: 'percent',
        minimumFractionDigits: 2,
        signDisplay: 'never',
      })}
      )
    </span>
  )
}

interface DeltaNumberProps {
  deltaAbsValue: number
  deltaRelValue: number
  disabled?: boolean
  alwaysBlue?: boolean
}

export default function DeltaNumber(props: DeltaNumberProps) {
  const { deltaAbsValue, deltaRelValue, disabled, alwaysBlue } = props

  return (
    <div className="DeltaNumber">
      <DeltaAbsNumber
        value={deltaAbsValue}
        disabled={disabled}
        alwaysBlue={alwaysBlue}
      />
      <DeltaRelNumber
        value={deltaRelValue}
        disabled={disabled}
        alwaysBlue={alwaysBlue}
      />
    </div>
  )
}
